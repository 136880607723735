import React, { ChangeEventHandler, useState } from 'react';

interface SearchBarFormProps {
  mapOverlaysShown: any;
  setMapOverlays: (data: any) => any;
  submitHandler: (params: any) => any;
  listView: any;
  poisExist: any;
  searchParams: any;
}

export function SearchBarForm({
  mapOverlaysShown,
  setMapOverlays,
  listView,
  poisExist,
  searchParams,
  submitHandler,
}: SearchBarFormProps) {
  const BLANK_FORM_DATA = {
    min_bedrooms: '',
    max_bedrooms: '',
    layout: '',
    min_start_date: '',
    max_start_date: '',
    min_price: '',
    max_price: '',
    lease_type: '',
    pictures: false,
    pets: false,
    laundry: false,
    utilities: false,
    furnished: false,
    parking: false,
    wheelchair_access: false
  };

  const INITIAL_FORM_DATA = Object.keys(searchParams).length ? searchParams : BLANK_FORM_DATA

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [moreFiltersShown, setMoreFiltersShown] = useState(
    ['pictures', 'pets', 'laundry', 'utilities', 'furnished', 'parking', 'wheelchair_access'].some((f) => formData[f])
  );
  const [overlaysShown, setOverlaysShown] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const handleFormChange: ChangeEventHandler<any> = (evt) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFormDirty(true);
    setFormData((fData: any) => ({ ...fData, [name]: value }));
  };

  const handleMapOverlayChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { name, checked } = evt.target;
    setMapOverlays({ ...mapOverlaysShown, [name]: checked });
  };

  const anyFiltersSet = () => {
    return !Object.keys(formData).every((f) => formData[f] === '' || formData[f] === false || formData[f] === null);
  };

  const clearFilters = () => {
    setFormData(BLANK_FORM_DATA);
    setMoreFiltersShown(false);
    if (submitHandler) {
      setFormDirty(false);
      submitHandler(BLANK_FORM_DATA);
    }
  };

  const handleSubmit = (evt: any) => {
    // if no submitHandler passed in, fallback to normal form behavior
    if (submitHandler) {
      evt.preventDefault();
      setFormDirty(false);
      submitHandler(formData);
    }
  };

  return (
    <form className="search-bar" action="/housing" method="get" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 col-lg-auto py-2 d-none">
          <div className="mb-1">Housing type</div>
          <select
            id="layout"
            className="form-select"
            onChange={handleFormChange}
            name="layout"
            value={searchParams.layout}
          >
            <option value="">Any</option>
            <option value="Shared room">Shared room</option>
            <option value="Private room">Private room</option>
            <option value="Entire place">Entire place</option>
          </select>
        </div>

        <div className="col-md-6 col-lg px-3 py-2">
          <label htmlFor="min-price" className="form-label">Price</label>
          <div className="row g-2">
            <div className="col">
              <div className="input-group">
                <span className="input-group-text ps-2 pe-2">$</span>
                <input
                  style={{ width: '60px' }}
                  className="form-control"
                  type="number"
                  placeholder="Any"
                  id="min-price"
                  aria-label="minimum price"
                  onChange={handleFormChange}
                  name="min_price"
                  value={formData.min_price}
                />
              </div>
            </div>

            <span className="col-auto price-between mx-0 text-center">to</span>

            <div className="col">
              <label htmlFor="max-price" className="sr-only">Max price</label>
              <div className="input-group">
                <span className="input-group-text ps-2 pe-2">$</span>
                <input
                  style={{ width: '60px' }}
                  className="form-control"
                  type="number"
                  placeholder="Any"
                  id="max-price"
                  aria-label="maximum price"
                  onChange={handleFormChange}
                  name="max_price"
                  value={formData.max_price}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg px-3 py-2">
          <label htmlFor="min-bedrooms" className="form-label">Bedrooms</label>
          <div className="row g-2">
            <div className="col">
              <select
                className="form-select"
                id="min-bedrooms"
                onChange={handleFormChange}
                name="min_bedrooms"
                value={formData.min_bedrooms}
              >
                <option value="">Any</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>

            <span className="col-auto bed-size-between mx-1 text-center">to</span>

            <div className="col">
              <label htmlFor="max-bedrooms" className="sr-only">Max bedrooms</label>
              <select
                className="form-select"
                id="max-bedrooms"
                onChange={handleFormChange}
                name="max_bedrooms"
                value={formData.max_bedrooms}
              >
                <option value="">Any</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 py-2 px-3">
          <div className='pb-2 text-center'>
            <a href='javascript:void(0);'
              onClick={() => setMoreFiltersShown((moreFiltersShown) => !moreFiltersShown)}
            >
              {moreFiltersShown ? 'Hide filters' : 'Show more filters'}
            </a>
            { anyFiltersSet() &&
              <span>
                {` / `}
                <a href='javascript:void(0);' onClick={clearFilters}>Clear filters</a>
              </span>
            }
          </div>
          <button className="btn btn-danger jump-btn" type="submit" disabled={!formDirty}>
            Jump!
          </button>
        </div>
      </div>

      {moreFiltersShown && (
        <div className="mt-2">
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <a
                className={`nav-link ${overlaysShown ? '' : 'active'}`}
                onClick={() => setOverlaysShown(false)}
                style={{ cursor: 'pointer' }}
              >
                Filters
              </a>
            </li>
            {!listView && (
              <li className="nav-item">
                <a
                  className={`nav-link ${overlaysShown ? 'active' : ''}`}
                  onClick={() => setOverlaysShown(true)}
                  style={{ cursor: 'pointer' }}
                >
                  Overlays
                </a>
              </li>
            )}
          </ul>

          {overlaysShown ? (
            <div className="col">
              {poisExist && (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="points_of_interest"
                    checked={mapOverlaysShown.points_of_interest}
                    onChange={handleMapOverlayChange}
                    name="points_of_interest"
                  />
                  <label className="form-check-label" htmlFor="points_of_interest">
                    Show points of interest
                  </label>
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="transit"
                  checked={mapOverlaysShown.transit}
                  onChange={handleMapOverlayChange}
                  name="transit"
                />
                <label className="form-check-label" htmlFor="transit">
                  Show transit map
                </label>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="pictures"
                    checked={formData.pictures}
                    value={formData.pictures}
                    onChange={handleFormChange}
                    name="pictures"
                  />
                  <label className="form-check-label" htmlFor="pictures">
                    Require pictures
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="pets"
                    checked={formData.pets}
                    value={formData.pets}
                    onChange={handleFormChange}
                    name="pets"
                  />
                  <label className="form-check-label" htmlFor="pets">
                    Pets allowed
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="laundry"
                    checked={formData.laundry}
                    value={formData.laundry}
                    onChange={handleFormChange}
                    name="laundry"
                  />
                  <label className="form-check-label" htmlFor="laundry">
                    Has washer / dryer
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="utilities"
                    checked={formData.utilities}
                    value={formData.utilities}
                    onChange={handleFormChange}
                    name="utilities"
                  />
                  <label className="form-check-label" htmlFor="utilities">
                    Some utilities included
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="furnished"
                    checked={formData.furnished}
                    value={formData.furnished}
                    onChange={handleFormChange}
                    name="furnished"
                  />
                  <label className="form-check-label" htmlFor="furnished">
                    Furnished
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="parking"
                    checked={formData.parking}
                    value={formData.parking}
                    onChange={handleFormChange}
                    name="parking"
                  />
                  <label className="form-check-label" htmlFor="parking">
                    Designated parking included
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="wheelchair_access"
                    checked={formData.wheelchair_access}
                    value={formData.wheelchair_access}
                    onChange={handleFormChange}
                    name="wheelchair_access"
                  />
                  <label className="form-check-label" htmlFor="wheelchair_access">
                    Handicap / disability access
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="min_start_date" className="form-label">Earliest move-in date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="min_start_date"
                    name="min_start_date"
                    onChange={handleFormChange}
                    value={formData.min_start_date}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="max_start_date" className="form-label">Latest move-in date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="max_start_date"
                    name="max_start_date"
                    onChange={handleFormChange}
                    value={formData.max_start_date}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
}
